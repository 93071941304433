export const apiKey = 'sF18IaiyYe2WfMUkWTTRr22NpvLTckmfrgFFRRM7'
export const tisApiKey = apiKey
export const stage = 'dev'
export const domain = `https://api.${stage}.tis.coke.com`
export const employee = `${domain}/employees`
export const profile = `${domain}/profile`
export const user = `https://pfas-api.${stage}.tis.coke.com/users`
export const region = `${domain}/regions`
export const pfas = `https://pfas-api.dev.tis.coke.com/v0.1`
// export const azureClientId = 'b95143ee-2d47-4070-a6e7-d487658756c3'
export const azureClientId = '2cb00e82-6a72-48ef-ab64-8f209932ab09'
export const azureTenantId = 'eb36d020-2c35-44de-bc7a-554cc979b26d' 
export const pfasApiKey = 'nvib736a902fkuhhabk86bfoa62ibj'
export const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1UFM6t42Uxig6q7CzMMS
E2NEK3TrZuXDvh0Yz/fmgsVKbHEi8b426E7wQ8GXZiOqxjxhd1Dx0xNahFvbtJJC
4E4oX8AGrJ9cQrEc4/5HLseu7gboTvXhf2pSj0yDgSPXmOC+MYUW0+ENu4769PT5
vUXhCNLAOWSRbAStxJseRNkR7Qa6XHbhLR770QyH8bgxckYLnZaS89iLNg5IHDNK
6JdTnmjSYGkFSQogUVOVe7FKIq1gv1tStUkKFLPw2XJMfxd+u7R23gCAxr+uISQ9
bzTLRXbzhOtpJlNUoWfXULTrWdnTCgobjbkt4eegVlNVjkotwOVwp6cBHuTidpPu
GQALWGlDyZKTSqsroL6etDkOG2wxelD6YwvAfZR9FaxJ3etdCu09HYhfDJa2Z/HV
/EkxaalKyL+Ie8UatQ3OOpoEBIBxpJLRXVXy8tYz1ADCQ9i8qu7fMACZy+gOYOJA
L7G0Ln6ZNk/GkmTkU37MXp1cvazuX+5uJHZSsTiRy9M5f3Vdm/c5sd2daap8wj0z
+I4ARsdqvjuY7KjuidG29kr3d1EoIuTuvEXmwdlGedLe7NJdQYqKYCvUCh3dBBJ5
kWQHqXk+K81n0DmLwq4nNoIeNrQUBoZArM54qnUfsQGMLd5hAP++dmMK9dOeucCd
O5Vr27rL9FgZK1qKLpjwxlUCAwEAAQ==
-----END PUBLIC KEY-----
`